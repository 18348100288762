import axios, { AxiosResponse } from 'axios';

import { PlaylistData, Track } from 'store/playlists.types';
import { openPopup } from 'u9/utils/popup';

import {
  ApiArtists,
  ApiPlaylist,
  ApiRecommendations,
  ApiTrack,
  ApiUserPlaylist,
  ApiUserPlaylists,
  ApiUserProfile,
  AuthData
} from './api.types';
import { parseUrlQuery } from './url';

export const TOKEN_TYPE = 'access_token';
export const RECOMMENDED_COUNT = 10;

export const baseURL = 'https://api.spotify.com/v1';

const client = axios.create({ baseURL });
client.defaults.headers.common = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const setClientToken = (token: string) => {
  client.defaults.headers['Authorization'] = `Bearer ${token}`;
};

// Auth
export const login = () => openPopup(`${process.env.PUBLIC_URL}/api/login`, 450, 730);
export const refreshToken = (token: string): Promise<AxiosResponse<AuthData>> => axios.get(`${process.env.PUBLIC_URL}/api/refreshToken/${token}`);

// Generic
export const getApiTrack = (id: string): Promise<AxiosResponse<ApiTrack>> => axios.get(`${process.env.PUBLIC_URL}/api/tracks/${id}`);
export const getApiPlaylist = (id: string): Promise<AxiosResponse<ApiPlaylist>> => client.get(`/playlists/${id}`);
export const getArtists = (artistIds: string[]): Promise<AxiosResponse<ApiArtists>> => client.get(`/artists?ids=${artistIds.join(encodeURIComponent(','))}`);
export const getRecommendations = (trackIds: string[], limit = RECOMMENDED_COUNT): Promise<AxiosResponse<ApiRecommendations>> =>
  client.get(`/recommendations?limit=${limit}&seed_tracks=${trackIds.join(encodeURIComponent(','))}`);

// User-specific
export const getUserProfile = (): Promise<AxiosResponse<ApiUserProfile>> => client.get('/me');
export const getUserPlaylists = (limit = 20, offset = 0): Promise<AxiosResponse<ApiUserPlaylists>> =>
  client.get(`/me/playlists?limit=${limit}&offset=${offset}`);

export const createPlaylist = (userId: string, name: string, description: string): Promise<AxiosResponse<ApiUserPlaylist>> =>
  client.post(`/users/${userId}/playlists`, { name, description, public: true });

export const setPlaylistItems = (playlistId: string, uriArray: string[]): Promise<AxiosResponse<string>> =>
  client.put(`/playlists/${playlistId}/tracks`, { uris: uriArray });

export const setPlaylistCover = (playlistId: string, base64Cover: string): Promise<AxiosResponse<string>> =>
  client.put(`/playlists/${playlistId}/images`, base64Cover, { headers: { ...client.defaults.headers, 'Content-Type': 'image/jpeg' } });

// Utils
export const getTrackFromApiTrack = (apiTrack: ApiTrack, isCurated = false): Track => ({
  id: apiTrack.id,
  isCurated,
  artists: apiTrack.artists.map((artist) => artist.name),
  artistIds: apiTrack.artists.map((artist) => artist.id),
  title: apiTrack.name,
  previewUrl: apiTrack.preview_url,
  url: apiTrack.external_urls.spotify,
  image: apiTrack.album.images[0] ? apiTrack.album.images[0].url : ''
});

export const getTracksFromApiPlaylist = (apiPlaylist: ApiPlaylist, isCurated = false) =>
  apiPlaylist.tracks.items.map(({ track }) => getTrackFromApiTrack(track, isCurated));

export const getPlaylistDataFromApiPlaylist = (apiPlaylist: ApiPlaylist, isCurated = false): PlaylistData => ({
  id: apiPlaylist.id,
  url: apiPlaylist.external_urls.spotify,
  tracks: getTracksFromApiPlaylist(apiPlaylist, isCurated)
});

export const getFavoritePlaylistName = (template: string, userName: string) => template.replace('[username]', userName);

const findUserPlaylist = async (field: keyof Omit<ApiUserPlaylist, 'external_urls'>, value: string, limit = 50, offset = 0) => {
  let playlist: ApiUserPlaylist = null;
  let nextLimit = limit;
  let nextOffset = offset;

  do {
    const { data: playlists } = await getUserPlaylists(nextLimit, nextOffset);
    playlist = playlists.items.find((apiPlaylist) => apiPlaylist[field] === value);

    if (!playlist && playlists.next) {
      const nextQuery = parseUrlQuery(playlists.next.replace(/(.*)\?/, ''));
      nextLimit = +nextQuery.limit;
      nextOffset = +nextQuery.offset;
    }

    if (!playlists.next) break;
  } while (!playlist);

  if (playlist) return getApiPlaylist(playlist.id);
  return null;
};

export const findUserPlaylistWithName = async (name: string, limit = 50, offset = 0) => {
  return findUserPlaylist('name', name, limit, offset);
};

export const findUserPlaylistWithId = async (id: string, limit = 50, offset = 0) => {
  return findUserPlaylist('id', id, limit, offset);
};

export const getFavoritePlaylistData = async (playlistName: string, anthemId: string) => {
  const apiPlaylist = await findUserPlaylistWithName(playlistName);
  const favoritePlaylistData = apiPlaylist ? getPlaylistDataFromApiPlaylist(apiPlaylist.data) : null;

  if (favoritePlaylistData) {
    favoritePlaylistData.tracks = favoritePlaylistData.tracks.map((track) => ({
      ...track,
      // Set the anthem as a curated track
      isCurated: track.id === anthemId
    }));
  }

  return favoritePlaylistData;
};

export const getArtistsGenres = async (artistIds: string[]) => {
  const { data: apiArtists } = await getArtists(artistIds);
  const genres = Array.from(new Set(apiArtists.artists.map((artist) => artist.genres[0])));
  return genres;
};

export const getRecommendedTracks = async (favoritedTracks: Track[]) => {
  const trackIds = favoritedTracks.map((track) => track.id).slice(0, 5);

  const { data: recommendations } = await getRecommendations(trackIds);
  return recommendations.tracks.map((track) => getTrackFromApiTrack(track, true));
};
